import axios from 'axios-jsonp-pro'
import { deleteCookie, getCookies } from '@/utils/cookie'
import {
  API_GDPR_GET_DECISION,
  API_GDPR_SET_DECISION,
  IP_DETECT_SERVICE_URL,
  encodeFingerprint,
  prepareBrowserData,
  prepareGeoData,
} from '@/const/gdpr'
import {
  SET_GDPR_FINGERPRINT,
  SET_GDPR_DECISIONS,
  SET_GDPR_SAVED,
} from '@/store/mutationsConstants'

const state = () => ({
  fingerprint: {
    geo: {
      ip: null,
      city: {
        geoNameId: null,
        isoCode: null,
        code: null,
        names: {},
      },
      country: {
        geoNameId: null,
        isoCode: null,
        code: null,
        names: {},
      },
      continent: {
        geoNameId: null,
        isoCode: null,
        code: null,
        names: {},
      },
      location: {
        accuracyRadius: null,
        latitude: null,
        longitude: null,
        timeZone: null,
      },
      postalCode: null,
      subdivisions: null,
    },
    browser: {
      appCodeName: null,
      appName: null,
      appVersion: null,
      cookieEnabled: null,
      doNotTrack: null,
      cpuThreads: null,
      language: null,
      languages: null,
      platform: null,
      product: null,
      productSub: null,
      userAgent: null,
      vendor: null,
      vendorSub: null,
    },
  },
  decisions: {
    required: true,
    analytics: false,
    marketing: false,
  },
  saved: false,
})

const getters = {
  gdpr: (state) => state,
  fingerprint: (state) => state.fingerprint,
  decisions: (state) => state.decisions,
  decisionsRequired: (state) => state.decisions.required,
  decisionsAnalytics: (state) => state.decisions.analytics,
  decisionsMarketing: (state) => state.decisions.marketing,
  gdprSaved: (state) => state.saved,
}

const mutations = {
  [SET_GDPR_FINGERPRINT](state, fingerprint) {
    state.fingerprint = fingerprint
  },
  [SET_GDPR_DECISIONS](state, decisions) {
    state.decisions = decisions
  },
  [SET_GDPR_SAVED](state, saved) {
    state.saved = saved
  },
}

const actions = {
  populate({ commit }, { fingerprint, response }) {
    try {
      commit(SET_GDPR_FINGERPRINT, fingerprint)

      if (response !== null && response !== undefined) {
        if (response.data.payload) {
          const decoded = JSON.parse(response.data.payload)
          commit(SET_GDPR_DECISIONS, decoded)
          commit(SET_GDPR_SAVED, true)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  },
  async getFingerprint({ commit }) {
    try {
      await axios.get(IP_DETECT_SERVICE_URL).then((response) => {
        const geo = prepareGeoData(response, this.state)
        const browser = prepareBrowserData(window.navigator, this.state)

        const fingerprint = { geo, browser }

        this.dispatch('gdpr/getDecisions', { fingerprint })
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  },
  async getDecisions({ commit }, { fingerprint }) {
    try {
      const hashedFingerprint = encodeFingerprint(fingerprint)

      await axios
        .get(API_GDPR_GET_DECISION, {
          params: {
            fingerprint: hashedFingerprint,
          },
        })
        .then((response) => {
          this.dispatch('gdpr/populate', { fingerprint, response })
          this.dispatch('gdpr/initCookies')
        })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  },
  async setDecisions({ commit }, { decisions }) {
    try {
      const fingerprint = this.state.gdpr.fingerprint
      const hashedFingerprint = encodeFingerprint(fingerprint)
      const hashedDecisions = JSON.stringify({
        required: this.state.gdpr.decisions.required,
        analytics: decisions.analytics || false,
        marketing: decisions.marketing || false,
      })

      await axios
        .post(API_GDPR_SET_DECISION, {
          fingerprint: hashedFingerprint,
          payload: hashedDecisions,
        })
        .then((response) => {
          this.dispatch('gdpr/populate', { fingerprint, response })
          this.dispatch('gdpr/initCookies')
        })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  },
  async initCookies({ commit }) {
    try {
      const cookies = getCookies()

      if (this.getters['landings/isWorldFranchise']) {
        await this.app.$initGoogleTagManager('GTM-WFCK4S4')
      } else {
        await this.app.$initGoogleTagManager('GTM-PDCNZFV')
      }

      await this.app.$setUtmInCookie()
      await this.app.$initSourceBuster()
      if (
        this.state.gdpr.decisions.analytics ||
        this.$getTextMainSetting('isRussianFranchise')
      ) {
        await this.app.$initFbPixel(this.state.landings.metrics.fbMetricId)
        await this.app.$initGoogleAnalytics(
          this.state.landings.metrics.gaMetric
        )
        if (this.state.landings.metrics.gtmMetricId) {
          await this.app.$initGoogleTagManager(
            this.state.landings.metrics.gtmMetricId
          )
        }
        if (this.state.landings.metrics.tmrMetricId) {
          await this.app.$initMailRuRating(
            this.state.landings.metrics.tmrMetricId
          )
        }
        if (this.state.landings.metrics.vkMetricId) {
          await this.app.$initVkontakteMetric(
            this.state.landings.metrics.vkMetricId
          )
        }
        if (this.state.landings.metrics.yaMetricId) {
          await this.app.$initYandexMetric(
            this.state.landings.metrics.yaMetricId
          )
        }
      }

      if (!this.state.gdpr.decisions.marketing) {
        for (const cookie in cookies) {
          if (cookie.includes('_cmg_') || cookie.includes('_comagic_')) {
            deleteCookie(cookie)
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
