export default function ({ store, redirect, route, i18n }) {
  const routePath = route.path

  if (routePath.includes('/mailing')) {
    return redirect('/privacy#mail_agreement')
  }

  if (routePath.includes('/cookie')) {
    return redirect('/privacy#cookies')
  }

  if (store.getters['landings/textMainSetting']('template_version') === 2) {
    const urlsMap = new Map([
      ['/coding/camp', '/intensive'],
      ['/coding/creative', '/scratch'],
      ['/coding/frontend', '/frontend'],
      ['/coding/gamedesign', '/game-design'],
      ['/coding/graphicdesign', '/graphic-design'],
      ['/coding/junior', '/osnovy-programmirovaniya'],
      ['/coding/pc', '/computernaya-gramotnost'],
      ['/coding/pytpro', '/python'],
      ['/coding/pystart', '/python'],
      ['/coding/unity', '/unity'],
      ['/coding/videocontent', '/videoblogging'],
      ['/coding/websites', '/web-razrabotka'],
      ['/coding', '/'],
    ])

    let redirectUrl = ''

    urlsMap.forEach((dst, src) => {
      if (routePath.includes(src)) {
        if (redirectUrl === '') {
          redirectUrl = '/' + i18n.locale + dst
        }
      }
    })

    if (redirectUrl !== '') {
      return redirect(redirectUrl)
    }
  }

  const alwaysAvailablePaths = [
    '/payment',
    '/sitemap',
    '/contacts',
    '/mailing',
    '/privacy',
  ]
  if (alwaysAvailablePaths.some((item) => routePath.includes(item))) {
    return
  }

  if (!store.getters['landings/landingIsActive']) {
    return redirect('/')
  }

  if (routePath === '/coding/visual') {
    return redirect('/coding/creative')
  }
}
